// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Theme colors
$primary:       								  	#72decf !important;
$primary-hover:    									#0BB7AF !important;
$primary-light:    									#C9F7F5 !important;
$primary-inverse:  									#FFFFFF !important;

$info:       									    #6993FF !important;
$info-light:    									#E1E9FF !important;
$info-hover:    									#4A7DFF !important;
$info-inverse:  									#FFFFFF !important;

$success:       									#3699FF !important;
$success-hover:    									#187DE4 !important;
$success-light:    									#E1F0FF !important;
$success-inverse:  									#FFFFFF !important;


.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: #72decf !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}


.textarea-div {
  height: 80px;
  overflow-y: scroll;
}

.row-classes {
  padding: 20px 0px;
}

.btn.btn-primary, .btn.btn-hover-primary.focus:not(.btn-text), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #fff;
  background-color: $primary;
  border-color:  $primary;
}

.label-input-required::after {
  content: "*";
  color:$danger;
}

.width-date-picker, .react-datepicker-wrapper{
  width: 100% !important;
}